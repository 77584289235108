import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  AdminCancelClassBookingMutation,
  AdminCancelClassBookingMutationVariables,
  ClassBookingStatus,
} from '../../../generated/graphql';

export interface ICancelClassBookingButtonProps {
  classBookingId: string;
  onComplete: () => void;
}

const cancelClassBookingMutation = gql`
  mutation adminCancelClassBooking($input: AdminCancelClassBookingInput!) {
    adminCancelClassBooking(input: $input) {
      booking {
        status
      }
    }
  }
`;

export const CancelClassBookingButton: FC<ICancelClassBookingButtonProps> = props => {
  const { classBookingId, onComplete } = props;
  const [open, setOpen] = React.useState(false);
  const [cancelBooking, { data, loading, error }] = useMutation<
    AdminCancelClassBookingMutation,
    AdminCancelClassBookingMutationVariables
  >(cancelClassBookingMutation);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    await cancelBooking({
      variables: {
        input: {
          classBookingId,
        },
      },
    });
    onComplete();
  };

  const getResults = () => {
    if (!(loading || error || data)) return undefined;

    return (
      <>
        <DialogTitle>Result</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {loading && 'Canceling class...'}
            {error && error.message}
            {data &&
              (data.adminCancelClassBooking?.booking?.status ===
              ClassBookingStatus.Canceled
                ? 'Class was canceled since no replacement teacher could be found'
                : 'A replacement was found! The class will continue as planed')}
          </DialogContentText>
        </DialogContent>
      </>
    );
  };

  return (
    <div>
      <Button
        style={{ marginTop: 8 }}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        Cancel Class
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">Cancel Class</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Canceling the class means the assigned teacher is no longer capable
            of attending the class. We will try to assign a new teacher to the
            class, however if none are available the student will be notified
            that the class is no longer taking place.
          </DialogContentText>
        </DialogContent>

        {getResults()}

        {data || error ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button disabled={loading} onClick={handleClose} color="primary">
              I changed my mind, DO NOT CANCEL
            </Button>
            <Button
              disabled={loading}
              onClick={handleConfirm}
              color="secondary"
            >
              Cancel the class
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
