import * as React from 'react';

import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';

const CountDownClock = styled.div`
  font-size: 64px;
  margin-bottom: 16px;
`;
interface CountDownTimeProps {
  startTime: DateTime;
  durationInMinutes: number;
}

export const CountDownTimer: React.FC<CountDownTimeProps> = ({
  startTime,
  durationInMinutes,
}) => {
  const endTime = startTime.plus({ minutes: durationInMinutes });

  const [time, setTime] = React.useState(startTime.diffNow());
  React.useEffect(() => {
    const timeout = setTimeout(() => setTime(startTime.diffNow()), 250);

    return () => clearTimeout(timeout);
  }, [time]);

  const minutesUntilStartTime = time.as('minutes');
  const minutesUntilEndTime = time
    .plus({ minutes: durationInMinutes })
    .as('minutes');

  const isInProgress = minutesUntilStartTime < 0 && minutesUntilEndTime > 0;

  // Starts within 12 hours
  const startsSoon =
    minutesUntilStartTime < 12 * 60 && minutesUntilStartTime > 0;

  // Ended more than 20 minutes ago
  const endedLongAgo = minutesUntilEndTime < -20;

  // Ended within 20 minutes
  const justEnded = minutesUntilEndTime > -20 && minutesUntilEndTime < 0;

  if (startsSoon) {
    return (
      <div>
        <Typography style={{ color: 'white' }} variant="subtitle1">
          Class starts in
        </Typography>
        <CountDownClock>{time.toFormat('hh : mm : ss')}</CountDownClock>
      </div>
    );
  }

  if (isInProgress) {
    const style = minutesUntilEndTime < 5 ? { color: 'red' } : undefined;
    return (
      <div>
        <Typography style={{ color: 'white' }} variant="subtitle1">
          Class in progress - time remaining
        </Typography>
        <CountDownClock style={style}>
          {endTime.diffNow().toFormat('mm : ss')}
        </CountDownClock>
      </div>
    );
  }

  if (justEnded) {
    return (
      <div>
        <Typography style={{ color: 'white' }} variant="subtitle1">
          Class ended
        </Typography>
        <CountDownClock style={{ color: 'red' }}>
          {`- ${endTime.diffNow().negate().toFormat('mm : ss')}`}
        </CountDownClock>
      </div>
    );
  }

  if (endedLongAgo) {
    const endTimeDuration = endTime
      .diffNow()
      .negate()
      .toFormat('hh:mm')
      .split(':');
    return (
      <div>{`Class ended ${endTimeDuration[0]} hours and ${endTimeDuration[1]} minutes ago`}</div>
    );
  }

  return null;
};
