import * as React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';

import startImage from '../../images/star.png';
import { logger } from '../../logger';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: '#1e1e21',
    minHeight: 500,
    alignItems: 'center',
    justifyContent: 'center',
  },
  star: {
    width: 50,
    height: 50,
    marginBottom: 28,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: 'white',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '0.8rem',
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: 24,
    marginBottom: 40,
  },
  error: {
    textAlign: 'center',
    fontSize: '0.8rem',
    color: 'red',
    marginBottom: 16,
  },
}));

interface ClassCompletePageProps {
  onSubmitNote: () => Promise<void>;
  enabled: boolean;
}

export const ClassComplete = ({
  onSubmitNote,
  enabled,
}: ClassCompletePageProps) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [submiting, setSubmiting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const styles = useStyles();

  const handleSubmitNote = async () => {
    setDialogOpen(false);
    setSubmiting(true);
    setError(null);
    try {
      await onSubmitNote();
    } catch (e) {
      setError('Could not submit the feedback note.');
      logger.error('Could not submit the feedback note', { error: e });
    } finally {
      setSubmiting(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const renderButton = () => {
    if (submiting) {
      return <CircularProgress color="primary" />;
    }

    if (enabled) {
      return (
        <Button color="primary" variant="contained" onClick={handleOpenDialog}>
          Close and submit feedback
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <img src={startImage} className={styles.star} />
        <h1 className={styles.title}>Lesson Complete</h1>
        <h2 className={styles.subtitle}>
          Make sure to leave the student feedback over on the right hand side
        </h2>
        {error ? <h2 className={styles.error}>{error}</h2> : null}
        {renderButton()}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Submit feedback</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Before submitting the feedback, please double-check the content you
            have written, make sure no typographical or grammatical errors.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Check it again
          </Button>
          <Button
            onClick={handleSubmitNote}
            color="primary"
            variant="contained"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
