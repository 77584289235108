import React, { FC, useState } from 'react';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Tabs, Tab, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { SectionTypeStatsPanelQuery } from '../../../generated/graphql';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';

const sectionTypeStatsPanelQuery = gql`
  query SectionTypeStatsPanel($lessonId: ID!, $userId: ID!) {
    admin {
      lessonProgress(lessonId: $lessonId, userId: $userId) {
        sectionTypeStats {
          sectionType
          score
        }
      }
    }
    node(id: $userId) {
      ... on User {
        id
        sectionTypeStats {
          sectionType
          score
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  tab: {
    flexGrow: 1,
  },
}));

export interface ISectionTypeStatsPanelProps {
  userId: string;
  lessonId: string;
}

export const SectionTypeStatsPanel: FC<ISectionTypeStatsPanelProps> = ({
  lessonId,
  userId,
}) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { loading, error, data } = useQuery<SectionTypeStatsPanelQuery>(
    sectionTypeStatsPanelQuery,
    { variables: { lessonId, userId } },
  );
  if (loading) return <CircularProgress />;
  if (error) return <GraphQLErrorDisplay error={error} />;

  const sectionTypeStats =
    (tabIndex === 0
      ? data?.admin?.lessonProgress?.sectionTypeStats
      : data?.node?.__typename === 'User' && data?.node?.sectionTypeStats) ||
    [];

  const hasData = sectionTypeStats.length > 0;

  return (
    <TableContainer component={Paper}>
      <Tabs
        value={tabIndex}
        onChange={(_evt, newVal) => setTabIndex(newVal)}
        aria-label="simple tabs example"
      >
        <Tab label="Lesson grades" className={classes.tab} />
        <Tab label="Overall grades" className={classes.tab} />
      </Tabs>

      <Table>
        <TableBody>
          {!hasData && (
            <TableRow>
              <TableCell>No data</TableCell>
            </TableRow>
          )}
          {sectionTypeStats?.map(({ sectionType, score }) => (
            <TableRow key={sectionType}>
              <TableCell align="left">{sectionType}</TableCell>
              <TableCell align="left">{Math.round(100 * score)}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
