import React, { FC } from 'react';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import { DateTime } from 'luxon';

import { UserClassBookingHistoryFragment } from '../generated/graphql';

export const userClassBookingHistoryFragment = gql`
  fragment userClassBookingHistory on User {
    classBookingHistory(after: $after, first: $first) {
      edges {
        node {
          id
          bookingTimeslot {
            startTime
          }
          classTopic {
            id
            title
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export interface IUserClassBookingHistoryProps {
  user: UserClassBookingHistoryFragment;
}

export const UserClassBookingHistory: FC<IUserClassBookingHistoryProps> = ({
  user,
}) => {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" style={{ margin: 16 }}>
        Class history
      </Typography>

      <Table aria-label="User Information">
        <TableBody>
          {user.classBookingHistory?.edges?.map(edge => {
            if (!edge || !edge.node) return null;

            const booking = edge.node;
            return (
              <TableRow key={booking.id}>
                <TableCell align="left">
                  {`${DateTime.fromISO(booking.bookingTimeslot.startTime)
                    .diffNow()
                    .negate()
                    .toFormat('d')} days ago`}
                </TableCell>
                <TableCell align="left">
                  <Link to={`/booking/${booking.id}`}>
                    {booking.classTopic?.title}
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
