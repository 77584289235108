/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import StreamPlayer from 'agora-stream-player';
import styled from '@emotion/styled';
import {
  IStreamWithPromise,
  IClientWithPromise,
  // eslint-disable-next-line import/no-unresolved
} from 'agoran-awe/types/promisify';
import { useMediaStream } from './hooks';
import { AgoraRTC } from './utils/AgoraEnhancer';
import cameraActive from './assets/cam_active@2x.png';
import cameraInactive from './assets/cam_disabled@2x.png';
import micActive from './assets/mic_active@2x.png';
import micInactive from './assets/mic_disabled@2x.png';
import hangup from './assets/hangup@2x.png';
import screenShareActive from './assets/screenshare_active.png';
import screenShareInactive from './assets/screenshare_inactive.png';
import { JoinVideoClassroomVideoStream } from '../../../generated/graphql';
import { logger } from '../../../logger';

const VideoChatContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #1e1e21;
  min-height: 500px;
`;

const StreamsContainer = styled.div`
  height: calc(100vh - 60px);
  position: relative;
  width: 250px;
  background: #313131;
  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ControlsContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-bottom: 4px;
  margin-top: 8px;
`;

const MainStreamContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ButtonImage = styled.img`
  height: 50px;
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
`;

const styles = {
  stream: {
    width: '250 px',
    height: '250 px',
  },
  activeStream: {
    width: '250 px',
    height: '250 px',
    border: '2px solid red',
  },
  mainStream: {
    flex: 1,
    height: '100%',
  },
};

interface ChannelInfo {
  channel: string;
  account?: string;
  token?: string;
}

interface Props {
  appId: string;
  onJoin: (videoStream?: JoinVideoClassroomVideoStream) => Promise<ChannelInfo>;
  onLeave: () => void;
  allowSpyMode?: boolean;
}

export const VideoChat = ({ appId, onJoin, onLeave, allowSpyMode }: Props) => {
  const shareScreenClient = useRef<IClientWithPromise | null>(null);
  const videoClient = useRef<IClientWithPromise | null>(null);
  const shareScreenStream = useRef<IStreamWithPromise | null>(null);
  const videoStream = useRef<IStreamWithPromise | null>(null);

  const [isJoined, setisJoined] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const allStreamsList = useMediaStream(videoClient.current);

  if (!AgoraRTC) return null;

  const join = async () => {
    if (!AgoraRTC) return;
    const vidClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
    videoClient.current = vidClient;
    setIsLoading(true);
    try {
      const { channel, account, token } = await onJoin(
        JoinVideoClassroomVideoStream.Camera,
      );
      await vidClient.init(appId);
      await vidClient.join(token || null, channel, account || null);
      const vidStream = AgoraRTC.createStream({
        video: true,
        audio: true,
        screen: false,
      });
      await vidStream.init();
      await vidClient.publish(vidStream);
      videoStream.current = vidStream;
      setisJoined(true);
      setIsCameraOn(true);
      setIsMicOn(true);
    } catch (err) {
      logger.error('Failed to join', { error: err });
    } finally {
      setIsLoading(false);
    }
  };

  const spy = async () => {
    if (!AgoraRTC) return;
    const vidClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
    videoClient.current = vidClient;
    setIsLoading(true);
    try {
      const { channel, account, token } = await onJoin(
        JoinVideoClassroomVideoStream.None,
      );
      await vidClient.init(appId);
      await vidClient.join(token || null, channel, account || null);
      setisJoined(true);
    } catch (err) {
      logger.error('Failed to join as spy', { error: err });
    } finally {
      setIsLoading(false);
    }
  };

  const stopShareScreen = async () => {
    if (!shareScreenClient.current || !shareScreenStream.current || !AgoraRTC)
      return;
    try {
      shareScreenStream.current.close();
      await shareScreenClient.current.unpublish(shareScreenStream.current);
      await shareScreenClient.current.leave();
      shareScreenStream.current = null;
      shareScreenClient.current = null;

      setIsSharingScreen(false);
    } catch (err) {
      logger.error('Failed to stop sharing screen', { error: err });
    }
  };

  const onStopScreenShare = async () => {
    await stopShareScreen();
  };

  const leave = async () => {
    setIsLoading(true);
    try {
      await stopShareScreen();
      if (videoStream.current && videoClient.current) {
        videoStream.current.close();
        await videoClient.current.unpublish(videoStream.current);
      }
      await videoClient.current?.leave();

      videoStream.current = null;
      videoClient.current = null;

      setisJoined(false);
      setIsCameraOn(false);
      setIsMicOn(false);
      onLeave();
      console.log('Left channel', { variant: 'info' });
    } catch (err) {
      logger.error('Failed to leave', { error: err });
    } finally {
      setIsLoading(false);
    }
  };

  const shareScreen = async () => {
    if (!AgoraRTC) return;
    try {
      if (!shareScreenClient.current) {
        const { channel, account, token } = await onJoin(
          JoinVideoClassroomVideoStream.Screen,
        );
        const screenClient = AgoraRTC.createClient({
          mode: 'rtc',
          codec: 'h264',
        });
        await screenClient.init(appId);
        await screenClient.join(token || null, channel, account || null);
        shareScreenClient.current = screenClient;
      }

      if (!shareScreenStream.current) {
        const screenStream = AgoraRTC.createStream({
          video: false,
          audio: false,
          screen: true,
        });
        await screenStream.init();
        screenStream.on('stopScreenSharing', onStopScreenShare);
        shareScreenStream.current = screenStream;
      }

      await shareScreenClient.current.publish(shareScreenStream.current);
      setIsSharingScreen(true);
    } catch (err) {
      logger.error('Failed to share screen', { error: err });
    }
  };

  const screenShareButton = (
    <ButtonImage
      src={isSharingScreen ? screenShareActive : screenShareInactive}
      onClick={isSharingScreen ? stopShareScreen : shareScreen}
    />
  );

  const turnCameraOn = () => {
    if (!videoStream.current) return;

    videoStream.current.unmuteVideo();
    setIsCameraOn(true);
  };

  const turnCameraOff = () => {
    if (!videoStream.current) return;

    videoStream.current.muteVideo();
    setIsCameraOn(false);
  };

  const cameraButton = (
    <ButtonImage
      src={isCameraOn ? cameraActive : cameraInactive}
      onClick={isCameraOn ? turnCameraOff : turnCameraOn}
    />
  );

  const turnMicOn = () => {
    if (!videoStream.current) return;

    videoStream.current?.unmuteAudio();
    setIsMicOn(true);
  };

  const turnMicOff = () => {
    if (!videoStream.current) return;

    videoStream.current?.muteAudio();
    setIsMicOn(false);
  };

  const micButton = (
    <ButtonImage
      src={isMicOn ? micActive : micInactive}
      onClick={isMicOn ? turnMicOff : turnMicOn}
    />
  );

  const hangupButton = <ButtonImage src={hangup} onClick={leave} />;

  const JoinBtn = () => {
    return (
      <Button
        color={'primary'}
        onClick={join}
        variant="contained"
        disabled={isLoading}
      >
        Join
      </Button>
    );
  };

  const SpyBtn = () => {
    if (allowSpyMode) {
      return (
        <Button
          color={'primary'}
          onClick={spy}
          variant="contained"
          disabled={isLoading}
        >
          Spy
        </Button>
      );
    }
    return null;
  };

  return (
    <VideoChatContainer>
      <StreamsContainer>
        {isJoined && (
          <React.Fragment>
            <ControlsContainer>
              {screenShareButton}
              {micButton}
              {cameraButton}
              {hangupButton}
            </ControlsContainer>
            {allStreamsList.map((stream: any) => {
              if (stream.getId() !== shareScreenStream?.current?.getId()) {
                return (
                  <StreamPlayer
                    key={stream.getId()}
                    stream={stream}
                    style={styles.stream}
                    fit="contain"
                    autoChange={false}
                  />
                );
              }

              return null;
            })}
          </React.Fragment>
        )}
      </StreamsContainer>

      <MainStreamContainer>
        {shareScreenStream.current && (
          <StreamPlayer
            stream={shareScreenStream.current}
            style={styles.mainStream}
            fit="contain"
          />
        )}
        {!isJoined && (
          <div>
            <JoinBtn />
            <SpyBtn />
          </div>
        )}
      </MainStreamContainer>
    </VideoChatContainer>
  );
};
