import React, { FC } from 'react';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { Link } from 'gatsby';

import {
  ClassBookingUserInfoFragment,
  AdminPermission,
} from '../generated/graphql';
import { helloLevelToCefrLevel } from '../util';

export const classBookingUserInfoFragment = gql`
  fragment classBookingUserInfo on User {
    id
    name
    region
    created
    credits
    progress {
      currentLevelNumber
      points
    }
    premium {
      isEnabled
      endTime
      planType
    }
  }
`;

export interface IClassBookingUserInfoProps {
  user: ClassBookingUserInfoFragment;
  permissions: AdminPermission[];
}

export const ClassBookingUserInfo: FC<IClassBookingUserInfoProps> = ({
  user,
  permissions,
}) => {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" style={{ margin: 16 }}>
        {permissions.includes(AdminPermission.ReadUserData) ? (
          <Link to={`/user/${user.id}`}>
            {user.name || 'Unregistered User'}
          </Link>
        ) : (
          <p>{user.name || 'Unregistered User'}</p>
        )}
      </Typography>

      <Table aria-label="User Information">
        <TableBody>
          <TableRow>
            <TableCell align="left">Region</TableCell>
            <TableCell align="left">{user.region}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Hello Level</TableCell>
            <TableCell align="left">
              {user.progress?.currentLevelNumber}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">CEFR Level</TableCell>
            <TableCell align="left">
              {helloLevelToCefrLevel(user.progress?.currentLevelNumber || 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Points</TableCell>
            <TableCell align="left">{user.progress?.points}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Premium</TableCell>
            <TableCell align="left">
              {user.premium?.isEnabled ? user.premium?.planType : 'NO'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Credits</TableCell>
            <TableCell align="left">{user.credits}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
