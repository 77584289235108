import { useState, useEffect } from 'react';
import {
  IClientWithPromise,
  IStreamWithPromise,
  // eslint-disable-next-line import/no-unresolved
} from 'agoran-awe/types/promisify';

export const useMediaStream = (client: IClientWithPromise | null): any[] => {
  const [localStream, setLocalStream] = useState<
    IStreamWithPromise | undefined
  >(undefined);
  const [remoteStreamList, setRemoteStreamList] = useState<
    IStreamWithPromise[]
  >([]);

  useEffect(() => {
    let mounted = true;
    // add when subscribed
    const addRemote = (evt: any) => {
      if (!mounted) {
        return;
      }
      const { stream } = evt;
      setRemoteStreamList(streamList => [...streamList, stream]);
    };
    // remove stream
    const removeRemote = (evt: any) => {
      const { stream } = evt;
      if (stream) {
        const id = stream.getId();

        setRemoteStreamList(previousRemoteList => {
          const index = previousRemoteList.findIndex(
            item => item.getId() === id,
          );
          if (index === -1) return previousRemoteList;
          const newRemoteList = previousRemoteList.slice();
          newRemoteList.splice(index, 1);
          return newRemoteList;
        });
      }
    };

    const cleanUpState = () => {
      setLocalStream(undefined);
      setRemoteStreamList([]);
    };
    // subscribe when added
    const doSub = (evt: any) => {
      if (!mounted) {
        return;
      }

      client?.subscribe(evt.stream, { audio: true, video: true });
    };

    // add when published
    const addLocal = (evt: any) => {
      if (!mounted) {
        return;
      }
      const { stream } = evt;
      const { stop, close } = stream;
      stream.close = (func => () => {
        func();
        cleanUpState();
      })(close);
      stream.stop = (func => () => {
        func();
        cleanUpState();
      })(stop);
      setLocalStream(stream);
    };

    if (client) {
      client.on('stream-published', addLocal);
      client.on('stream-added', doSub);
      client.on('stream-subscribed', addRemote);
      client.on('peer-leave', removeRemote);
      client.on('stream-removed', removeRemote);
    } else {
      cleanUpState();
    }

    return () => {
      mounted = false;
      if (client) {
        client.off('stream-published', addLocal);
        client.off('stream-added', doSub);
        client.off('stream-subscribed', addRemote);
        client.off('peer-leave', removeRemote);
        client.off('stream-removed', removeRemote);
      }
    };
  }, [client]);

  return localStream
    ? [localStream].concat(remoteStreamList)
    : remoteStreamList;
};
