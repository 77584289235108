import * as React from 'react';

import { Router } from '@reach/router';
import IndexLayout from '../components/common/Layout';
import ClassBookingPage from '../components/subpages/ClassBookingPage';
import { Route } from '../components/Route';

const ClassBookingIndexPage = () => {
  return (
    <IndexLayout>
      <Router>
        <Route path="/booking/:classBookingId" component={ClassBookingPage} />
      </Router>
    </IndexLayout>
  );
};

export default ClassBookingIndexPage;
