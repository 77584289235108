import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import styled from '@emotion/styled';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';

import { VideoChat } from '../../class/VideoChat/VideoChat';
import {
  ClassBookingQuery,
  ClassBookingFragment,
  ClassBookingViewerFragment,
  JoinVideoClassroomMutation,
  JoinVideoClassroomMutationVariables,
  JoinVideoClassroomVideoStream,
  AdminClassBookingNoteFragment,
  AdminCreateClassBookingNoteMutation,
  AdminCreateClassBookingNoteMutationVariables,
  ClassBookingStatus,
  AdminPermission,
} from '../../../generated/graphql';
import { normalizeId } from '../../../normalizeId';
import {
  classBookingUserInfoFragment,
  ClassBookingUserInfo,
} from '../../ClassBookingUserInfo';
import { CountDownTimer } from '../../ClassCountDownClock';
import {
  UserClassBookingHistory,
  userClassBookingHistoryFragment,
} from '../../UserClassBookingHistory';
import { ClassComplete } from '../ClassComplete';
import {
  ClassFeedbackNote,
  adminClassBookingNoteFragment,
} from './ClassFeedbackNote';
import { CancelClassBookingButton } from './CancelClassBookingButton';
import { SectionTypeStatsPanel } from './SectionTypeStatsPanel';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import { logger } from '../../../logger';

const classBookingFragment = gql`
  fragment ClassBooking on ClassBooking {
    id
    status
    classTopic {
      id
      title
      deckEmbedUrl
      lesson {
        id
      }
    }
    bookingTimeslot {
      startTime
      durationInMinutes
    }
    user {
      ...classBookingUserInfo
      ...userClassBookingHistory
    }
    channelIdentifier
    notes {
      ...adminClassBookingNote
    }
  }
  ${classBookingUserInfoFragment}
  ${userClassBookingHistoryFragment}
  ${adminClassBookingNoteFragment}
`;

const classBookingViewerFragment = gql`
  fragment ClassBookingViewer on Viewer {
    admin {
      id
      permissions
    }
  }
`;

const classBookingQuery = gql`
  query ClassBooking($id: ID!, $after: String, $first: Int) {
    node(id: $id) {
      ...ClassBooking
    }
    viewer {
      ...ClassBookingViewer
    }
  }
  ${classBookingFragment}
  ${classBookingViewerFragment}
`;

const joinVideoClassroomMutation = gql`
  mutation JoinVideoClassroom($input: JoinVideoClassroomInput!) {
    joinVideoClassroom(input: $input) {
      token
      account
      channel
    }
  }
`;

const adminCreateClassBookingNoteMutation = gql`
  mutation adminCreateClassBookingNote(
    $input: AdminCreateClassBookingNoteInput!
  ) {
    adminCreateClassBookingNote(input: $input) {
      note {
        ...adminClassBookingNote
      }
    }
  }
  ${adminClassBookingNoteFragment}
`;

const DetailsContainer = styled.div`
  background-color: #444449;
  padding: 20px;
  color: white;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  position: relative;
  width: 30vw;
  min-width: 370px;
`;

const ClassBookingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const TopicDetailsContainer = styled.div`
  margin-bottom: 32px;
`;
const OpenDeckButtonContainer = styled.div`
  margin-top: 10px;

  > button {
    margin-left: 8px;
  }
`;

const useStyles = makeStyles(theme => ({
  informationTableContainer: {
    marginTop: theme.spacing(1),
  },
  canceledBanner: {
    display: 'flex',
    height: '50px',
    width: '100%',
    backgroundColor: '#f50057',
    marginBottom: '20px',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  classBookingId: string;
}

interface TopicDetailsProps {
  title: string;
  startTime: string;
  deckEmbedUrl: string | null;
  durationInMinutes: number;
}

const TopicDetails: React.FC<TopicDetailsProps> = props => {
  const startTime = DateTime.fromISO(props.startTime);

  const submitIssue = () => logger.showFeedbackDialog();

  return (
    <TopicDetailsContainer>
      <CountDownTimer
        startTime={startTime}
        durationInMinutes={props.durationInMinutes}
      />
      <Typography style={{ color: 'white' }} variant="subtitle1">
        Topic
      </Typography>
      <Typography style={{ color: 'white' }} variant="h4">
        {props.title}
      </Typography>
      <Typography style={{ color: 'white' }} variant="h6">
        {startTime.toLocaleString(DateTime.DATETIME_FULL)}
      </Typography>
      <Typography style={{ color: 'white' }} variant="h6">
        {props.durationInMinutes} minutes
      </Typography>
      <OpenDeckButtonContainer>
        {props.deckEmbedUrl && (
          <Button
            color="primary"
            variant="contained"
            href={props.deckEmbedUrl}
            target="_blank"
          >
            Open deck
          </Button>
        )}
        <Button onClick={submitIssue} color="secondary" variant="contained">
          Report Tech Issue
        </Button>
      </OpenDeckButtonContainer>
    </TopicDetailsContainer>
  );
};

const AGORA_APP_ID = process.env.AGORA_APP_ID as string;

const ClassBookingPage = (props: Props) => {
  const classBookingId = normalizeId(props.classBookingId, 'ClassBooking');

  const { loading, error, data, refetch } = useQuery<ClassBookingQuery>(
    classBookingQuery,
    { variables: { id: classBookingId, first: 10 } },
  );

  if (error) return <GraphQLErrorDisplay error={error} />;

  if (!data || !data.node || data.node.__typename !== 'ClassBooking') {
    if (loading) return 'Loading...';

    return 'Not found :(';
  }

  return (
    <WrappedClassBookingPage
      booking={data.node}
      viewer={data.viewer}
      classBookingId={classBookingId}
      refresh={refetch}
    />
  );
};

const WrappedClassBookingPage = ({
  booking,
  viewer,
  classBookingId,
  refresh,
}: {
  booking: ClassBookingFragment;
  viewer: ClassBookingViewerFragment | null;
  classBookingId: string;
  refresh: () => void;
}) => {
  const [feedbackNote, setFeedbackNote] = React.useState<string>('');
  const [joinVideoClassroom] = useMutation<
    JoinVideoClassroomMutation,
    JoinVideoClassroomMutationVariables
  >(joinVideoClassroomMutation, {
    variables: { input: { classBookingId } },
  });
  const [createBookingNote] = useMutation<
    AdminCreateClassBookingNoteMutation,
    AdminCreateClassBookingNoteMutationVariables
  >(adminCreateClassBookingNoteMutation);

  const { user, classTopic, bookingTimeslot } = booking;
  const permissions = viewer?.admin?.permissions || [];

  const styles = useStyles();

  const isClassExceeded = (): boolean => {
    const endTime = DateTime.fromISO(bookingTimeslot.startTime).plus({
      minutes: bookingTimeslot.durationInMinutes,
    });
    return endTime.diffNow('seconds').seconds < 0;
  };

  const [classCompleted, setClassCompleted] = React.useState<boolean>(
    isClassExceeded(),
  );

  const handleJoinClassroom = async (
    videoStream?: JoinVideoClassroomVideoStream,
  ) => {
    const joinResult = await joinVideoClassroom({
      variables: { input: { classBookingId, videoStream } },
    });
    const channelInfo = joinResult.data?.joinVideoClassroom;
    if (!channelInfo) throw new Error('Invalid channel info');
    return channelInfo;
  };

  const handleLeaveClassroom = () => {
    // User leaves the chat & time passes after end time of the class,
    // can be a signal to determine the class is finished.
    if (isClassExceeded()) {
      setClassCompleted(true);
    }
  };

  const handleSubmitNote = async () => {
    await createBookingNote({
      variables: {
        input: {
          note: feedbackNote,
          classBookingId,
        },
      },
    });
    setFeedbackNote('');
    refresh();
  };

  const notes = React.useMemo(() => {
    if (!booking.notes) {
      return [];
    }

    return Array.isArray(booking.notes) ? booking.notes : [booking.notes];
  }, [booking]) as AdminClassBookingNoteFragment[];

  return (
    <ClassBookingContainer>
      {classCompleted ? (
        <ClassComplete
          enabled={!notes.length && !!feedbackNote}
          onSubmitNote={handleSubmitNote}
        />
      ) : (
        <VideoChat
          appId={AGORA_APP_ID}
          onJoin={handleJoinClassroom}
          onLeave={handleLeaveClassroom}
          allowSpyMode={permissions.includes(
            AdminPermission.ManageClassBooking,
          )}
        />
      )}
      <DetailsContainer>
        {booking.status === ClassBookingStatus.Canceled && (
          <div className={styles.canceledBanner}>Canceled</div>
        )}
        {classTopic && (
          <TopicDetails
            deckEmbedUrl={classTopic.deckEmbedUrl}
            title={classTopic.title}
            startTime={bookingTimeslot.startTime}
            durationInMinutes={bookingTimeslot.durationInMinutes}
          />
        )}
        <ClassFeedbackNote
          notes={notes}
          feedbackNote={feedbackNote}
          onFeedbackNoteChange={setFeedbackNote}
        />
        {user ? (
          <>
            <div className={styles.informationTableContainer}>
              <ClassBookingUserInfo user={user} permissions={permissions} />
            </div>
            <div className={styles.informationTableContainer}>
              <UserClassBookingHistory user={user} />
            </div>
          </>
        ) : null}
        {user && classTopic?.lesson && (
          <div className={styles.informationTableContainer}>
            <SectionTypeStatsPanel
              lessonId={classTopic.lesson.id}
              userId={user.id}
            />
          </div>
        )}
        <CancelClassBookingButton
          onComplete={refresh}
          classBookingId={booking.id}
        />
      </DetailsContainer>
    </ClassBookingContainer>
  );
};

export default ClassBookingPage;
