import * as React from 'react';
import gql from 'graphql-tag';
import {
  Typography,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from '@material-ui/core';

import { AdminClassBookingNoteFragment } from '../../../generated/graphql';

export const adminClassBookingNoteFragment = gql`
  fragment adminClassBookingNote on ClassBookingNote {
    id
    note
  }
`;

const useStyles = makeStyles(theme => ({
  note: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
  },
  textField: {
    display: 'flex',
    margin: theme.spacing(1),
  },
}));

interface Props {
  notes: AdminClassBookingNoteFragment[];
  feedbackNote: string;
  onFeedbackNoteChange: (note: string) => void;
}

export const ClassFeedbackNote = ({
  notes,
  feedbackNote,
  onFeedbackNoteChange,
}: Props) => {
  const styles = useStyles();

  return (
    <TableContainer component={Paper}>
      <Typography style={{ margin: 16 }} variant="h4">
        Feedback Notes
      </Typography>

      {notes.length ? (
        <Table aria-label="Feedback Notes">
          <TableBody>
            {notes.map(({ id, note }) => (
              <TableRow key={id}>
                <TableCell className={styles.note}>{note}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <TextField
          className={styles.textField}
          multiline={true}
          variant="outlined"
          label="Text your feedback"
          rows={6}
          onChange={e => onFeedbackNoteChange(e.target.value)}
        >
          {feedbackNote}
        </TextField>
      )}
    </TableContainer>
  );
};
